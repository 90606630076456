export type s3Endpoints={
   region:string;
   name:string;
   endpoint:string
}[]

export const AWSRegions:s3Endpoints=[
    { region: "us-east-1", name: "US East (N. Virginia)", endpoint: "s3.amazonaws.com" },
    { region: "us-east-2", name: "US East (Ohio)", endpoint: "s3.us-east-2.amazonaws.com" },
    { region: "us-west-1", name: "US West (N. California)", endpoint: "s3.us-west-1.amazonaws.com" },
    { region: "us-west-2", name: "US West (Oregon)", endpoint: "s3.us-west-2.amazonaws.com" },
    { region: "af-south-1", name: "Africa (Cape Town)", endpoint: "s3.af-south-1.amazonaws.com" },
    { region: "ap-east-1", name: "Asia Pacific (Hong Kong)", endpoint: "s3.ap-east-1.amazonaws.com" },
    { region: "ap-south-1", name: "Asia Pacific (Mumbai)", endpoint: "s3.ap-south-1.amazonaws.com" },
    { region: "ap-northeast-1", name: "Asia Pacific (Tokyo)", endpoint: "s3.ap-northeast-1.amazonaws.com" },
    { region: "ap-northeast-2", name: "Asia Pacific (Seoul)", endpoint: "s3.ap-northeast-2.amazonaws.com" },
    { region: "ap-northeast-3", name: "Asia Pacific (Osaka)", endpoint: "s3.ap-northeast-3.amazonaws.com" },
    { region: "ap-southeast-1", name: "Asia Pacific (Singapore)", endpoint: "s3.ap-southeast-1.amazonaws.com" },
    { region: "ap-southeast-2", name: "Asia Pacific (Sydney)", endpoint: "s3.ap-southeast-2.amazonaws.com" },
    { region: "ca-central-1", name: "Canada (Central)", endpoint: "s3.ca-central-1.amazonaws.com" },
    { region: "eu-central-1", name: "Europe (Frankfurt)", endpoint: "s3.eu-central-1.amazonaws.com" },
    { region: "eu-west-1", name: "Europe (Ireland)", endpoint: "s3.eu-west-1.amazonaws.com" },
    { region: "eu-west-2", name: "Europe (London)", endpoint: "s3.eu-west-2.amazonaws.com" },
    { region: "eu-west-3", name: "Europe (Paris)", endpoint: "s3.eu-west-3.amazonaws.com" },
    { region: "eu-north-1", name: "Europe (Stockholm)", endpoint: "s3.eu-north-1.amazonaws.com" },
    { region: "eu-south-1", name: "Europe (Milan)", endpoint: "s3.eu-south-1.amazonaws.com" },
    { region: "me-south-1", name: "Middle East (Bahrain)", endpoint: "s3.me-south-1.amazonaws.com" },
    { region: "sa-east-1", name: "South America (São Paulo)", endpoint: "s3.sa-east-1.amazonaws.com" }
  ];