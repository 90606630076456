import { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './SaveChangesBar.module.scss';
import messages from './messages';
import { WarningCircle } from 'src/common/icons';
import Button from 'src/components/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useSettingsMutation } from 'src/api/mutations/userMutations';
import { settingsAtom, updatedSettingsAtom } from 'src/shared/settings/index';
import { ACTION_TYPES } from 'src/shared/settings/base';
import { useAlerts } from 'src/common/AlertManager';

import {
  originalStoryBoardTypeAtom,
  currentStoryBoardTypeAtom
} from 'src/shared/settings/video';

import { useAtom } from 'jotai';
import { storageAlertStatus } from 'src/shared/settings/storage';


type SaveChangesBarProps = {
  className?: string;
  source?: string
};


const useUnsavedChangesManager = () => {
  const setSettings = useUpdateAtom(settingsAtom);

  const updatedSettings = useAtomValue(updatedSettingsAtom);
  const isUnsaved = updatedSettings ? true : false

  const undoActions = () => {
    setSettings({ type: ACTION_TYPES.UNDO_CHANGES });

  }

  return { isUnsaved, undoActions };

}



const useSettingsMutationManager = (source?: string) => {

  const { showSuccess, showAlert } = useAlerts();

  const [storageAlert, setStorageAlert] = useAtom(storageAlertStatus);

  const { formatMessage } = useIntl();

  const settingsMutation = useSettingsMutation({
    onError: (error) => {
      // setVisible(true);
      showAlert('An error occured, try again later.');
    },
    onSuccess: (result) => {
      if (result[0].value.status === 400) {
        showAlert('Invalid inputs. Please update values and retry');
        if (source === "storage") {
          setStorageAlert(true)
        } else {
          setStorageAlert(false)
        }
      } else if (result[0].value.status >= 500) {
        showAlert('Failed to update Settings. Please retry');
        setStorageAlert(false)
      } else {
        showSuccess(formatMessage(messages.successfulySaved));
        setStorageAlert(false)
      }

    },
  });

  return settingsMutation;

}


export const SaveChangesBar: React.FC<SaveChangesBarProps> = ({ className, source }) => {

  // Hooks
  const unsavedChangesManager = useUnsavedChangesManager()
  const settingsMutationManager = useSettingsMutationManager(source)

  // Event Handlers
  const handleSave = async () => await settingsMutationManager.mutateAsync(null);
  const handleCancel = () => unsavedChangesManager.undoActions();



  return (
    <motion.div initial={false} animate={{ height: unsavedChangesManager.isUnsaved ? 'auto' : 0 }} style={{ overflow: 'hidden' }}>
      <div className={cn([styles.SaveChangesBar, className])}>
        <p className={styles.unsavedChanges}>
          <span>
            <WarningCircle fontSize={18} color="var(--color-prime-400)" />
          </span>
          <FormattedMessage {...messages.unsavedChanges} />
        </p>
        <div className={styles.buttonGroup}>
          <Button mode="tertiary" size="small" onClick={handleCancel}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button mode="primary" size="small" onClick={handleSave} loading={settingsMutationManager.isLoading}>
            <FormattedMessage {...messages.save} />
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

export default SaveChangesBar;
