import { atom } from 'jotai';
import { createAtom } from './base';
import { getSettings } from './utils';

export const { currentStorageSettingsAtom, updatedStorageSettingsAtom, storageSettingsAtom } = createAtom('storage', {
  updater: (currentAtom: any, updatedAtom: any, get: any, set: any, action: any) => {
    const { payload } = action;

    const state = get(updatedAtom);

    set(updatedAtom, { ...state, ...payload });
  },
});

export const storageAlertStatus=atom<boolean>(false)

export const getStorageSettings = (get: any) => getSettings(get, [storageSettingsAtom, updatedStorageSettingsAtom]);
