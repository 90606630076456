import React, { useState, forwardRef, useImperativeHandle, Ref, Fragment } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { PuzzlePiece, Hash, Browser, Crop, CheckboxChecked, DownloadSimple, Pencil } from 'src/common/icons';
import { useAssetVisionQuery } from 'src/api/queries/assetsQueries';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { Asset } from 'src/types/TAssets';
import { getAssetMediaUrl } from 'src/api/functions/assetsApi';
import { TabTrigger } from './components/TabTrigger';
import { formatCoreData } from './helpers/formatCoreData';
import { config } from 'src/utils/config';
import styles from './Modal.module.scss';
import { useAlerts } from 'src/common/AlertManager';
import { AssetMetadata } from './components/AssetMetadata';
import { ConversionData, EDITION_MODE, SubtitleData } from './ModalEditor';
import useRoleManager from 'src/hooks/useRoleManager';
import ReactTooltip from 'react-tooltip';
import { formatSeconds } from 'src/utils/transform';

const tabs = {
  metadata: 'metadata',
  core: 'core',
  conversions: 'conversions',
  addon: 'addon',
};

export const ModalSidebar = forwardRef(
  (
    {
      asset,
      schemas,
      onEditConversion,
    }: {
      asset: Asset;
      schemas: any;
      onEditConversion: (type: EDITION_MODE, data: ConversionData | SubtitleData) => void;
    },
    ref: Ref<{ toggleSidebar: (forceState?: boolean) => void }>,
  ) => {
    const { data_schema_id = config.defaultSchemaId, ...metadata } = asset?.data ?? {};

    const [activeTab, setActiveTab] = useState(tabs.core);
    const [isGettingMediaUrl, setIsGettingMediaUrl] = useState(false);
    const [showSidebar, setShowSidebar] = useState<boolean>(true);
    const auth = useAuthUser();
    const accessToken = useAccessToken();
    const { showAlert } = useAlerts();
    const { isUser } = useRoleManager();

    const isImage = asset.content_type.startsWith('image/');

    let assetMetadata: [string, string | number][] = [];

    assetMetadata.push(['name', asset.name]);
    assetMetadata.push(['size', asset.size]);
    assetMetadata.push(['checksum', asset.checksum]);
    assetMetadata.push(['content type', asset.content_type]);
    if (asset.creator_id) {
      assetMetadata.push(['creator id', asset.creator_id]);
    }
    assetMetadata.push(['Upload Time', new Date(asset.upload_time).toLocaleString()]);
    if (asset.update_time) {
      assetMetadata.push(['Update Time', new Date(asset.update_time).toLocaleString()]);
    }
    assetMetadata.push(['public', asset.public.toString()]);

    assetMetadata = [
      ...assetMetadata,
      ...Object.entries(asset.metadata).filter(([title]) => {
        if (title === 'width' || title === 'height') {
          return asset.content_type.startsWith('video') || asset.content_type.startsWith('image');
        }
        if (title === 'duration') {
          return asset.content_type.startsWith('video') || asset.content_type.startsWith('audio');
        }
        if (title === 'dpi') {
          return asset.content_type.startsWith('image');
        }

        return title !== 'ColorPalette';
      }),
    ];

    let is_image_analysis = false;

    if (asset.addons_info.IMAGE_ANALYSIS?.available) {
      is_image_analysis = true;
    }

    const { data: visionData, error: visionError } = useAssetVisionQuery(asset.id, undefined, {
      enabled: isImage && is_image_analysis,
    });

    const isEditable = (conversion: any) => {
      const [type] = asset.content_type.split('/');

      if (type === 'image' && !['DEEPZOOM', 'HLS-VIDEO', 'STORYBOARD'].includes(conversion.title.toUpperCase()))
        return true;

      if (type === 'video' || type === 'audio') return /subtitles_\w+/g.test(conversion.title);

      return false;
    };

    useImperativeHandle(ref, () => ({
      toggleSidebar: (forceState?: boolean) => {
        setShowSidebar((old) => forceState ?? !old);
      },
    }));

    const handleDownload = async (key: string) => {
      setIsGettingMediaUrl(true);
      const url = await getAssetMediaUrl(asset.id, key, auth()!.accessId, 'download', accessToken);
      if (url.status === 404) {
        showAlert(`We're not able to provide you a downloable file at this time, please try again later.`);
        setIsGettingMediaUrl(false);
        return;
      }
      window.open(url);
      setIsGettingMediaUrl(false);
    };

    const handleEditConversion = async (conversion: any) => {
      const url = await getAssetMediaUrl(asset.id, conversion.title, auth()!.accessId, 'download', accessToken);
      const isSubtitle = /subtitles_\w+/g.test(conversion.title);

      if (isSubtitle) {
        onEditConversion(EDITION_MODE.SUBTITLE, {
          key: conversion.title,
          url,
        });
      } else {
        onEditConversion(EDITION_MODE.CONVERSION, {
          key: conversion.title,
          name: asset.name,
          url,
        });
      }
    };

    const CoreTab = () => (
      <div>
        {assetMetadata.map(([title, value]) => (
          <div className={styles.metadataRow} key={title}>
            <div>{title}</div>
            <div className={styles.metadataValue}>{formatCoreData(title, value)}</div>
          </div>
        ))}
        {asset.errors?.original && <div className={styles.errorDesc}>{asset.errors?.original}</div>}
      </div>
    );
    const contentType = asset?.content_type?.split('/')[0];

    const ConversionsTab = () => (
      <div className={styles.conversionsTabContainer}>
        {Object.keys(asset.conversions).length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              marginTop: '17rem',
            }}
          >
            <p style={{ fontWeight: 400, fontSize: '20px' }}>No Conversions created yet</p>
          </div>
        ) : (
          <table className={styles.conversionsTable}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Public</th>
                <th>Size</th>
                {contentType === 'video' || contentType === 'audio' ? (
                  <th>Duration</th>
                ) : contentType === 'image' ? (
                  <th>DPI</th>
                ) : null}
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(asset.conversions).map(([title, v]) => (
                <tr key={title}>
                  <td>{title}</td>
                  <td>{v?.public ? 'Y' : 'N'}</td>
                  <td>
                    {typeof v.width === 'number' && v.width > 0 && typeof v.height === 'number' && v.height > 0
                      ? `${v.width}×${v.height}`
                      : 'N/A'}
                  </td>
                  {contentType === 'video' || contentType === 'audio' ? (
                    v?.duration !== 0 && v?.duration ? (
                      <td>{formatSeconds(v?.duration)}</td>
                    ) : (
                      <td>N/A</td>
                    )
                  ) : contentType === 'image' ? (
                    v?.dpi !== 0 && v?.dpi ? (
                      <td>{v?.dpi}</td>
                    ) : (
                      <td>72</td>
                    )
                  ) : null}
                  {asset?.errors[title] ? (
                    <td>
                      <button data-tip={'Error'} className={styles.conversionTabError}>
                        Error
                        <ReactTooltip effect="solid" />
                      </button>
                    </td>
                  ) : (
                    <>
                      <td>
                        {isEditable({ title, ...v }) && !isUser && (
                          <button
                            className={styles.editButton}
                            onClick={() => handleEditConversion({ title, ...v })}
                            data-tip="Edit"
                          >
                            <ReactTooltip effect="solid" />
                            <Pencil />
                          </button>
                        )}
                      </td>
                      <td>
                        {!['DEEPZOOM', 'HLS-VIDEO', 'STORYBOARD'].includes(title.toUpperCase()) && (
                          <button
                            className={styles.downloadButton}
                            disabled={isGettingMediaUrl}
                            onClick={() => handleDownload(title)}
                            data-tip="Download"
                          >
                            <ReactTooltip effect="solid" />
                            <DownloadSimple />
                          </button>
                        )}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
    const AddonTab = () => (
      <div>
        {Object.entries(asset.addons_info).map(([title, value]) => (
          <Fragment key={title}>
            {value.available && (
              <div className={styles.metadataRow}>
                <div>{title}</div>
                <div>
                  <CheckboxChecked />
                </div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    );

    return (
      <>
        {showSidebar && (
          <div className={styles.details}>
            <div className={styles.tabs}>
              <TabTrigger keyName={tabs.core} activeKey={activeTab} setKey={setActiveTab} />
              <TabTrigger keyName={tabs.metadata} activeKey={activeTab} setKey={setActiveTab} />
              <TabTrigger keyName={tabs.conversions} activeKey={activeTab} setKey={setActiveTab} />
              <TabTrigger keyName={tabs.addon} activeKey={activeTab} setKey={setActiveTab} />
            </div>
            <div className={styles.scroller}>
              {activeTab === tabs.core && <CoreTab />}
              {activeTab === tabs.metadata && <AssetMetadata asset={asset} schemas={schemas} />}
              {activeTab === tabs.conversions && <ConversionsTab />}
              {activeTab === tabs.addon && <AddonTab />}
            </div>
            {visionData && (
              <div className={styles.tags}>
                {visionData.labelAnnotations?.slice(0, 5).map((e: { data: string; score: number }) => (
                  <span key={e.data}>{e.data}</span>
                ))}
                {visionData.labelAnnotations.length > 5 && <span>+{visionData.labelAnnotations.length - 5}</span>}
              </div>
            )}
          </div>
        )}
        {!showSidebar && (
          <div className={styles.tabButtons} onClick={() => setShowSidebar(true)}>
            <TabTrigger keyName={tabs.metadata} activeKey={activeTab} setKey={setActiveTab} icon={<Hash />} />
            <TabTrigger keyName={tabs.core} activeKey={activeTab} setKey={setActiveTab} icon={<Browser />} />
            <TabTrigger keyName={tabs.conversions} activeKey={activeTab} setKey={setActiveTab} icon={<Crop />} />
            <TabTrigger keyName={tabs.addon} activeKey={activeTab} setKey={setActiveTab} icon={<PuzzlePiece />} />
          </div>
        )}
      </>
    );
  },
);
